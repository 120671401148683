/* src/tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-background {
  background-image: linear-gradient(30deg, #00d6ff 10%, #00d41f 100%) !important;
}

body {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
}